import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import { Tooltip } from '../../common/StyledComponents';

export const MongoTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="right"
          title="Required. 요청 등의 정보를 저장하기 위한 몽고디비의 URI"
        >
          <TableRow>
            <TableHeadData>mongodbUri</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.mongodbUri}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        mongodbUri: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Required. 주민등록번호 등의 민감정보를 암호화하는데 사용할 key"
        >
          <TableRow>
            <TableHeadData>mongodbEncryptKey</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.mongodbEncryptKey}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        mongodbEncryptKey: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
