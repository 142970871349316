import React, { useState } from 'react';
import styled from 'styled-components';
import { GatewayInformation } from '../../api/gateway';
import { AdapterFormTable } from './AdapterFormTable';
import { GatewayFormTable } from './GatewayFormTable';
import { GatewayLoadFormTable } from './GatewayLoadFormTable';
import { GatewayStoreFormTable } from './GatewayStoreFormTable';
import { FormControlLabel, Checkbox, Divider } from '@mui/material';
import { EmrDbTable } from './EmrDbTable';
import { EmrPatientTable } from './EmrPatientTable';
import { PacsTable } from './PacsTable';
import { EmbeddedDicomTable } from './EmbeddedDicomTable';
import { EmbeddedMongoTable } from './EmbeddedMongoTable';
import { MongoTable } from './MongoTable';
import { CheckboxLabel, Title, Tooltip } from '../../common/StyledComponents';
import { GatewayListButtons } from './GatewayConfigButtons';
import { useCreateGateway, useGatewayJson, useUpdateGateway } from '../../hooks/gateway';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '../../common/Button';

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`;
const QueryResultContainer = styled(Container)`
  margin-top: 20px;
  white-space: pre-wrap;
`;
const ColumnDirectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export interface GatewayConfigProps {
  gateway?: GatewayInformation;
  onChangeGateway: React.Dispatch<React.SetStateAction<GatewayInformation | undefined>>;
}
export const GatewayConfig = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => {
  const queryClient = useQueryClient();
  const { mutate: createGateway } = useCreateGateway({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['GATEWAYS'] });
    },
  });
  const { mutate: updateGateway } = useUpdateGateway({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['GATEWAYS'] });
    },
  });

  const handleUpdateGateway = () => {
    if (gateway) {
      if (!gateway?.id) {
        createGateway(gateway);
      } else {
        updateGateway(gateway);
      }
      onChangeGateway(undefined);
    }
  };

  const handleCancelGateway = () => onChangeGateway(undefined);

  const [showQueryResult, setShowQueryResult] = useState(false);
  const { data } = useGatewayJson(gateway?.id ?? '');
  const queryResult = JSON.stringify(data, undefined, 2);

  return (gateway && (
    <div>
      <TitleContainer>
        <Title>Gateway Config</Title>
        <GatewayListButtons
          isUpdate={!!gateway.id}
          onClickUpdate={handleUpdateGateway}
          onClickCancel={handleCancelGateway}
        />
      </TitleContainer>
      <Button
        $width="100px"
        onClick={() => setShowQueryResult(prev => !prev)}>
        {showQueryResult ? '원문 숨기기' : '원문 보기'}
      </Button>
      <Container>
        <ColumnDirectionContainer>
          <Title>Gateway</Title>
          <GatewayFormTable gateway={gateway} onChangeGateway={onChangeGateway} />
          <Title>MongoDB Config</Title>
          <MongoTable gateway={gateway} onChangeGateway={onChangeGateway} />
          <Title>Adapter</Title>
          <AdapterFormTable gateway={gateway} onChangeGateway={onChangeGateway} />
        </ColumnDirectionContainer>
        <Divider orientation="vertical" />
        <ColumnDirectionContainer>
          <Title>Pacs Config</Title>
          <PacsTable gateway={gateway} onChangeGateway={onChangeGateway} />
        </ColumnDirectionContainer>
        <Divider orientation="vertical" />
        <ColumnDirectionContainer>
          <Title>Gateway Load</Title>
          <Tooltip
            placement="left"
            title="Required. default value: false. 영상 발급 사용 여부"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={gateway.config?.gatewayLoadEnabled ?? false}
                  onChange={e =>
                    onChangeGateway(gateway => ({
                      ...gateway,
                      config: { ...gateway?.config, gatewayLoadEnabled: e.target.checked },
                    } as GatewayInformation))
                  }
                />
              }
              label={<CheckboxLabel>gatewayLoadEnabled</CheckboxLabel>}
              sx={{ marginTop: '20px' }}
            />
          </Tooltip>
          {gateway.config?.gatewayLoadEnabled && (
            <GatewayLoadFormTable gateway={gateway} onChangeGateway={onChangeGateway} />
          )}
          <Title>Gateway Store</Title>
          <Tooltip
            placement="left"
            title="Required. default value: false. 영상 전달 사용 여부"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={gateway.config?.gatewayStoreEnabled ?? false}
                  onChange={e =>
                    onChangeGateway(gateway => ({
                      ...gateway,
                      config: { ...gateway?.config, gatewayStoreEnabled: e.target.checked },
                    } as GatewayInformation))
                  }
                />
              }
              label={<CheckboxLabel>gatewayStoreEnabled</CheckboxLabel>}
              sx={{ marginTop: '20px' }}
            />
          </Tooltip>
          {gateway.config?.gatewayStoreEnabled && (
            <GatewayStoreFormTable gateway={gateway} onChangeGateway={onChangeGateway} />
          )}
        </ColumnDirectionContainer>
        <Divider orientation="vertical" />
        <ColumnDirectionContainer>
          <Title>EMR</Title>
          <Tooltip
            placement="left"
            title="Required. default value: false. EMR 사용여부"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={gateway.config?.emrEnabled ?? false}
                  onChange={e =>
                    onChangeGateway(gateway => ({
                      ...gateway,
                      config: { ...gateway?.config, emrEnabled: e.target.checked },
                    } as GatewayInformation))
                  }
                />
              }
              label={<CheckboxLabel>emrEnabled</CheckboxLabel>}
              sx={{ marginTop: '20px' }}
            />
          </Tooltip>
          {gateway.config?.emrEnabled && (
            <>
              <Title>EMR DB</Title>
              <EmrDbTable gateway={gateway} onChangeGateway={onChangeGateway} />
              <Title>EMR Patient</Title>
              <EmrPatientTable gateway={gateway} onChangeGateway={onChangeGateway} />
            </>
          )}
        </ColumnDirectionContainer>
        <Divider orientation="vertical" />
        <ColumnDirectionContainer>
          <Title>Embedded Dicom</Title>
          <Tooltip
            placement="left"
            title="Required. default value: false. 임베디드 dicom 사용 여부. DICOM Send만 지원하는 병원에서 발급을 지원하고자 하는 경우 사용"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={gateway.config?.embeddedDicomEnabled ?? false}
                  onChange={e =>
                    onChangeGateway(gateway => ({
                      ...gateway,
                      config: { ...gateway?.config, embeddedDicomEnabled: e.target.checked },
                    } as GatewayInformation))
                  }
                />
              }
              label={<CheckboxLabel>embeddedDicomEnabled</CheckboxLabel>}
              sx={{ marginTop: '20px' }}
            />
          </Tooltip>
          {gateway.config?.embeddedDicomEnabled && (
            <EmbeddedDicomTable gateway={gateway} onChangeGateway={onChangeGateway} />
          )}
          <Title>Embedded MongoDB</Title>
          <Tooltip
            placement="left"
            title="Required. default value: true. 임베디드 몽고디비 사용 여부"
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={gateway.config?.embeddedMongoEnabled ?? false}
                  onChange={e =>
                    onChangeGateway(gateway => ({
                      ...gateway,
                      config: { ...gateway?.config, embeddedMongoEnabled: e.target.checked },
                    } as GatewayInformation))
                  }
                />
              }
              label={<CheckboxLabel>embeddedMongoEnabled</CheckboxLabel>}
              sx={{ marginTop: '20px' }}
            />
          </Tooltip>
          {gateway.config?.embeddedMongoEnabled && (
            <EmbeddedMongoTable gateway={gateway} onChangeGateway={onChangeGateway} />
          )}
        </ColumnDirectionContainer>
      </Container>
      {showQueryResult && (
        <QueryResultContainer>
          {queryResult}
        </QueryResultContainer>
      )}
    </div>
  ));
};
