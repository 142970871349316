import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import React from 'react';
import { Tooltip } from '../../common/StyledComponents';

export const GatewayFormTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <TableRow>
          <TableHeadData>id</TableHeadData>
          <TableData>{gateway?.id}</TableData>
        </TableRow>
        <TableRow>
          <TableHeadData>name</TableHeadData>
          <TableData>
            <SeamlessInput
              value={gateway?.name}
              onChange={e =>
                onChangeGateway(gateway =>
                  ({ ...gateway, name: e.target.value } as GatewayInformation),
                )
              }
            />
          </TableData>
        </TableRow>
        <TableRow>
          <TableHeadData>description</TableHeadData>
          <TableData>
            <SeamlessInput
              value={gateway?.description}
              onChange={e =>
                onChangeGateway(gateway =>
                  ({ ...gateway, description: e.target.value } as GatewayInformation),
                )
              }
            />
          </TableData>
        </TableRow>
        <TableRow>
          <TableHeadData>gatewayApiKey</TableHeadData>
          <TableData>
            <SeamlessInput
              value={gateway?.gatewayApiKey}
              onChange={e =>
                onChangeGateway(gateway =>
                  ({ ...gateway, gatewayApiKey: e.target.value } as GatewayInformation),
                )
              }
            />
          </TableData>
        </TableRow>
        <Tooltip
          placement="right"
          title="전달/발급 요청을 위해 업로드 해놓은 영상의 만료시간. 설정 안하면 12시간"
        >
          <TableRow>
            <TableHeadData>dicomBackupTime</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.dicomBackupTime}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway, dicomBackupTime: e.target.value } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <TableRow>
          <TableHeadData>registrationNumberRequired</TableHeadData>
          <TableData>
            <TableCheckbox
              checked={gateway?.registrationNumberRequired}
              onChange={e =>
                onChangeGateway(gateway => ({
                  ...gateway,
                  config: { ...gateway?.config, registrationNumberRequired: e.target.checked },
                } as GatewayInformation))
              }
            />
          </TableData>
        </TableRow>
        <Tooltip
          placement="left"
          title="Required. default value: PT10M. 현장발급한 영상의 만료 시간"
        >
          <TableRow>
            <TableHeadData>gatewayShareToPatientExpireTime</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayShareToPatientExpireTime}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayShareToPatientExpireTime: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
