import './App.css';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useCheckSession, useSignIn } from './hooks/oauth';
import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { Layout } from './features/Layout/Layout';
import { DashBoard } from './features/GatewayDashboard/DashBoard';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const RedirectLogin = () => {
  const signIn = useSignIn();

  useEffect(() => {
    signIn();
  }, [signIn]);

  return null;
};

interface ProtectedRouterProps {
  notLoggedInComponent?: JSX.Element;
}
const ProtectedRouter = ({ notLoggedInComponent }: ProtectedRouterProps) => {
  const { data: session, isLoading } = useCheckSession();

  return isLoading ? (
    <CircularProgress />
  ) : session ? (
    <Outlet />
  ) : (
    notLoggedInComponent ?? <RedirectLogin />
  );
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <BrowserRouter>
        <Routes>
          <Route  element={<ProtectedRouter />}>
            <Route element={<Layout />}>
              <Route path="/" element={<DashBoard />}/>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" />}/>
          <Route path="/login" element={<RedirectLogin />}/>
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
