/* eslint-disable react/prop-types */
import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  display: inline-block;
  position: relative;
  min-width: 70px;
  padding: 8px;
  text-align: center;
  cursor: text;
`;

const BackgroundSpan = styled.span`
  position: absolute; // flow context 에서 제외
  top: 0;
  left: 0;
  max-width: 300px; // 최대 길이 지정
  font-size: 14px;
  overflow: hidden; // 최대 길이보다 넘치는 텍스트 제거
  visibility: hidden; // width 는 계산되지만 보이진 않게 처리
  white-space: pre; // space 도 전부 표현하게
`;

const Input = styled.input`
  margin: 0;
  min-width: 1px;
  max-width: 100%;
  height: 1em;
  padding: 0;
  border: none;
  font-size: 12px;
  outline: none;
  text-align: center;

  /* Remove number arrow */
  // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:not([placeholder='']) {
    min-width: 100%;
  }
  &:invalid {
    color: red;
  }
`;

export const SeamlessInput = (
  props: React.InputHTMLAttributes<HTMLInputElement>,
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);

  useLayoutEffect(() => {
    if (!inputRef.current) return;
    if (!spanRef.current) return;

    // 3px 은 0.1 을 입력할 때 0. 까지 입력해도 value 는 0 이라 . 이 안 보여서 조금 늘려줌
    inputRef.current.style.width = `${spanRef.current.offsetWidth + 3}px`;

    if (props.value === undefined) {
      inputRef.current.value = '';
    }
  }, [props.value]);

  return (
    <Label>
      <BackgroundSpan ref={spanRef}>{props.value}</BackgroundSpan>
      <Input {...props} type="text" ref={inputRef} />
    </Label>
  );
};
