import { SimpleTable } from '../../common/Table';
import React, { useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/table-core';
import { useGateways } from '../../hooks/gateway.ts';
import { GatewayInformation } from '../../api/gateway';
import { Row } from '@tanstack/react-table';
import styled from 'styled-components';

interface GatewayNameTableProps {
  selectedGatewayId?: string;
  onSelectGateway: (gateway?: GatewayInformation) => void;
}
export const GatewayNameTable = ({
  selectedGatewayId,
  onSelectGateway,
}: GatewayNameTableProps) => {
  const [columnHelper] = useState(() => createColumnHelper<GatewayInformation>());
  const { data: gateways } = useGateways();

  const onClickRow = (row: Row<GatewayInformation>) => {
    onSelectGateway(row.original);
  };

  const columns = useMemo(
    () => [columnHelper.accessor('name', {
      header: '병원 이름',
      cell: ({ getValue, row }) => (
        <div
          style={{
            padding: '8px',
            backgroundColor: selectedGatewayId === row.original.id ? '#d0ebff' : '#fff',
          }}
          onClick={() => onClickRow(row)}
        >
          {getValue()}
        </div>
      ),
      size: 200,
    })],
    [onClickRow],
  );

  return (
    <SimpleTable
      columns={columns}
      data={gateways ?? []}
    />
  );
};
