import React from 'react';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ArrowContainer = styled.div`
  min-width: 100px;
  margin-top: 50px;
  text-align: center;
`;

export const ArrowBlock = () => {
  return (
    <ArrowContainer>
      <ArrowForwardIosIcon fontSize="large" />
    </ArrowContainer>
  );
};
