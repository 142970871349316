import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import React from 'react';
import { Tooltip } from '../../common/StyledComponents';

export const EmrDbTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. EMR DB 주소"
        >
          <TableRow>
            <TableHeadData>emrDbUrl</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrDbUrl}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrDbUrl: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. EMR DB username"
        >
          <TableRow>
            <TableHeadData>emrDbUsername</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrDbUsername}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrDbUsername: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. EMR DB password"
        >
          <TableRow>
            <TableHeadData>emrDbPassword</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrDbPassword}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrDbPassword: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. EMR DB 드라이버"
        >
          <TableRow>
            <TableHeadData>emrDbDriver</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrDbDriver}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrDbDriver: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. EMR 결과 등의 캐싱을 사용할지 여부"
        >
          <TableRow>
            <TableHeadData>cacheEnabled</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.cacheEnabled}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, cacheEnabled: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);