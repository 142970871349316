import { useCallback } from 'react';
import { requestCheckSession, requestSignIn, requestSignOut } from '../api/oauth.ts';
import { MutationOption } from './utils.ts';
import { useMutation, useQuery } from '@tanstack/react-query';

export const useSignIn = () => {
  const signIn = useCallback(async () => {
    await requestSignIn();
  }, []);
  return signIn;
};

export const useSignOut = (options?: MutationOption<void, unknown>) => {
  const signOut = useCallback(async () => {
    await requestSignOut();
  }, []);
  return useMutation({ mutationFn: signOut, ...options });
};

export const useCheckSession = () => {
  return useQuery({
    queryKey: ['CHECK_SESSION'],
    queryFn: async () => {
      try {
        await requestCheckSession();
        return true;
      } catch {
        return false;
      }
    },
  });
};
