import axiosInst from './index';
import { BACKEND_URL, KEYCLOAK_LOGIN_URL } from '../env.ts';

export const requestSignIn = async () => {
  const redirectUrl = new URL(`${BACKEND_URL}${KEYCLOAK_LOGIN_URL}`);
  location.href = redirectUrl.href;
};

export const requestSignOut = async () => {
  return axiosInst.post(`${BACKEND_URL}/signout`);
};

export const requestCheckSession = async () => {
  return axiosInst.get(`${BACKEND_URL}/session`);
};
