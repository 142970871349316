import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import React from 'react';
import { Tooltip } from '../../common/StyledComponents';

export const EmbeddedDicomTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. 임베디드 dicom AE Title"
        >
          <TableRow>
            <TableHeadData>embeddedDicomAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.embeddedDicomAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        embeddedDicomAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 임베디드 dicom 포트"
        >
          <TableRow>
            <TableHeadData>embeddedDicomPort</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.embeddedDicomPort}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        embeddedDicomPort: +e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 임베디드 dicom 사용 시 데이터를 저장할 경로"
        >
          <TableRow>
            <TableHeadData>embeddedDicomDir</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.embeddedDicomDir}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        embeddedDicomDir: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: true. 발급 후 영상을 삭제할지 여부"
        >
          <TableRow>
            <TableHeadData>embeddedDicomCleanup</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.embeddedDicomCleanup}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, embeddedDicomCleanup: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
