export const BACKEND_URL = window.env.BACKEND_URL;
export const KEYCLOAK_LOGIN_URL = window.env.KEYCLOAK_LOGIN_URL;

declare global {
  interface Window {
    env: {
      BACKEND_URL: string;
      KEYCLOAK_LOGIN_URL: string;
    };
  }
}
