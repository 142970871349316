import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import { Tooltip } from '../../common/StyledComponents';

export const EmbeddedMongoTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. default value: data/db. 임베디드 몽고디비의 데이터 저장 경로"
        >
          <TableRow>
            <TableHeadData>embeddedMongoDataDir</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.embeddedMongoDataDir}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        embeddedMongoDataDir: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
