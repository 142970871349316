import { styled } from 'styled-components';

export const Button = styled.button<{ $width?: string }>`
  width: ${p => p.$width ?? '100%'};
  background: #ffffff;
  border: 1px solid #0051ff;
  border-radius: 5px;

  cursor: pointer;
  color: #0051ff;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 24px;

  &:disabled {
    cursor: not-allowed;
    border: 1px solid #737b8c; // #0051ff 를 HSL 로 바꾸고 채도를 10% 로 낮춘 색깔
    color: #737b8c;
  }
`;
