import React from 'react';
import { GatewayNameTable } from './GatewayNameTable';
import { GatewayInformation } from '../../api/gateway';
import styled from 'styled-components';
import { GatewayListButtons } from './GatewayListButtons';
import { Spacer, Title } from '../../common/StyledComponents';
import { useDeleteGateway } from '../../hooks/gateway';
import { useQueryClient } from '@tanstack/react-query';

const Container = styled.div`
  min-width: 200px;
`;
const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface GatewayListProps {
  gateway?: GatewayInformation;
  onSelectGateway: (gateway?: GatewayInformation) => void;
}
export const GatewayList = ({
  gateway,
  onSelectGateway,
}: GatewayListProps) => {
  const queryClient = useQueryClient();
  const { mutate: deleteGateway } = useDeleteGateway({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['GATEWAYS'] });
    },
  });

  const handleAddGateway = () => {
    const emptyGateway: GatewayInformation = {
      name: '',
      description: '',
      gatewayApiKey: '',
      registrationNumberRequired: false,
    };
    onSelectGateway(emptyGateway);
  };
  const handleDeleteGateway = () => {
    if (gateway?.id) {
      deleteGateway(gateway?.id);
      onSelectGateway(undefined);
    }
  };

  return (
    <Container>
      <Title>Gateway List</Title>
      <Spacer $height="20px" />
      <TableWrapper>
        <GatewayNameTable
          selectedGatewayId={gateway?.id}
          onSelectGateway={onSelectGateway}
        />
      </TableWrapper>
      <GatewayListButtons
        onClickAdd={handleAddGateway}
        onClickDelete={handleDeleteGateway}
      />
    </Container>
  );
};
