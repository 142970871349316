import React, { PropsWithChildren, ReactNode } from 'react';
import {
  Table,
  TableContainer,
  TableWrapper,
} from './StyledComponents';

interface BasicTableContentProps {
  thead?: ReactNode;
  tbody: ReactNode;
  tfoot?: ReactNode;
}

export interface BasicTableStyleProps {
  width?: string;
}

type BasicTableProps = BasicTableContentProps & BasicTableStyleProps;
export const BasicTable = ({
  thead,
  tbody,
  tfoot,
  width,
  children,
}: BasicTableProps & PropsWithChildren) => {
  return (
    <TableWrapper>
      <TableContainer>
        <Table $width={width}>
          {thead && <thead>{thead}</thead>}
          {tbody && <tbody>{tbody}</tbody>}
          {tfoot && <tfoot>{tfoot}</tfoot>}
        </Table>
        {children}
      </TableContainer>
    </TableWrapper>
  );
};
