import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { LayoutHeader } from './LayoutHeader';

const LayoutBackground = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fd;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex: 1;
`;

const Main = styled.main`
  flex: 1;
  max-width: 100%;
  box-sizing: border-box;
  padding: 28px 24px 18px 28px;
  padding-top: 19px;
  padding-right: 21px;
  padding-left: 13px;
  margin-left: 10px;
  background: #ffffff;
  overflow: auto;
`;

export const Layout = () => {
  return (
    <main>
      <LayoutBackground>
        <LayoutHeader />
        <ContentWrapper>
          <Main>
            <Outlet />
          </Main>
        </ContentWrapper>
      </LayoutBackground>
    </main>
  );
};
