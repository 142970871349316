import React, { useState } from 'react';
import { GatewayList } from '../GatewayList';
import styled from 'styled-components';
import { ArrowBlock } from '../../common/ArrowBlock';
import { GatewayConfig } from '../GatewayConfig';
import { GatewayInformation } from '../../api/gateway';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const DashBoard = () => {
  const [selectedGateway, setSelectedGateway] = useState<GatewayInformation>();

  return (
    <Container>
      <GatewayList gateway={selectedGateway} onSelectGateway={setSelectedGateway} />
      <ArrowBlock />
      <GatewayConfig gateway={selectedGateway} onChangeGateway={setSelectedGateway} />
    </Container>
  );
};
