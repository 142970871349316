import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import React from 'react';
import { Tooltip } from '../../common/StyledComponents';

export const EmrPatientTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. EMR 환자 테이블 이름"
        >
          <TableRow>
            <TableHeadData>emrPatientTablename</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientTablename}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientTablename: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. PID column"
        >
          <TableRow>
            <TableHeadData>emrPatientId</TableHeadData>
            <TableData><SeamlessInput
              value={gateway?.config?.emrPatientId}
              onChange={e =>
                onChangeGateway(gateway =>
                  ({ ...gateway,
                    config: {
                      ...gateway?.config,
                      emrPatientId: e.target.value,
                    } } as GatewayInformation),
                )
              }
            />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. PID 저장 타입"
        >
          <TableRow>
            <TableHeadData>emrPatientIdType</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientIdType}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientIdType: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Accession Number column"
        >
          <TableRow>
            <TableHeadData>emrPatientAccessionNumber</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientAccessionNumber}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientAccessionNumber: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Accession Number 저장 타입"
        >
          <TableRow>
            <TableHeadData>emrPatientAccessionNumberType</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientAccessionNumberType}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientAccessionNumberType: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 환자 이름 column"
        >
          <TableRow>
            <TableHeadData>emrPatientName</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientName}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientName: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 환자 생년월일 column"
        >
          <TableRow>
            <TableHeadData>emrPatientBirth</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientBirth}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientBirth: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 환자 생년월일 저장 타입"
        >
          <TableRow>
            <TableHeadData>emrPatientBirthType</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientBirthType}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientBirthType: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 환자 생년월일 저장 형식"
        >
          <TableRow>
            <TableHeadData>emrPatientBirthFormat</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientBirthFormat}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientBirthFormat: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 환자 전화번호 column"
        >
          <TableRow>
            <TableHeadData>emrPatientPhone</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientPhone}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientPhone: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: false. 환자 전화번호에 하이픈이 포함되어 저장되어있는지 여부"
        >
          <TableRow>
            <TableHeadData>emrPatientPhoneHyphen</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.emrPatientPhoneHyphen}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, emrPatientPhoneHyphen: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 환자 성별 column"
        >
          <TableRow>
            <TableHeadData>emrPatientGender</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientGender}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientGender: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 여성의 저장 형식"
        >
          <TableRow>
            <TableHeadData>emrPatientGenderFemale</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientGenderFemale}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientGenderFemale: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 남성의 저장 형식"
        >
          <TableRow>
            <TableHeadData>emrPatientGenderMale</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientGenderMale}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientGenderMale: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. 기타 성별의 저장 형식"
        >
          <TableRow>
            <TableHeadData>emrPatientGenderOther</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientGenderOther}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientGenderOther: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Optional. 주민등록번호를 사용하는 경우 저장되어있는 column"
        >
          <TableRow>
            <TableHeadData>emrPatientRegistrationNumber</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.emrPatientRegistrationNumber ?? undefined}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        emrPatientRegistrationNumber: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
