import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSignOut } from '../../hooks/oauth';

const Container = styled.header`
  position: relative;
  width: 100%;
  height: 114px;
  background-color: ${p => '#00197e' || p.theme.colors.primary};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 30px 100px;
  box-sizing: border-box;
`;

const Title = styled.span`
  color: #f5f5f5;
  font-size: 26px;
  font-weight: 700;
  line-height: 39px;
`;

const SignOutButton = styled.button`
  width: 98px;
  height: 40px;
  border-radius: 32px;
  box-sizing: border-box;
  border: none;
  background-color: #0051ff;
  color: #ffffff;

  line-height: 21px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const LayoutHeader = () => {
  const navigate = useNavigate();

  const { mutate: signOut } = useSignOut({
    onSuccess() {
      navigate('/login');
    },
  });

  const onClickSignOutButton = async () => signOut();

  return (
    <Container>
      <Content>
        <div>
          <Title>Gateway DB Admin</Title>
        </div>
        <div>
          <SignOutButton onClick={onClickSignOutButton}>
            로그아웃
          </SignOutButton>
        </div>
      </Content>
    </Container>
  );
};
