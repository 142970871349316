import { styled } from 'styled-components';
import { Checkbox } from '@mui/material';
import { DateField } from '@mui/x-date-pickers';

export const TableWrapper = styled.div`
  overflow: auto;
`;

export const TableContainer = styled.div`
  position: relative;
  width: max-content;
  background-color: #ffffff;
`;

export const Table = styled.table<{ $width?: string }>`
  border-collapse: collapse;
  ${p => p.$width && `width: ${p.$width};`};
`;

export const TableRow = styled.tr`
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
`;

export const TableHeadData = styled.th`
  box-sizing: border-box;
  padding: 8px;
  background-color: #f3f4f6;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  text-align: center;
  
  color: #252b42;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  word-break: keep-all;
  & + & {
    border-left: 1px solid #e7e7e7;
  }
`;
export const TableData = styled.td`
  box-sizing: border-box;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;

  font-size: 12px;
  line-height: 1;
  word-break: keep-all;
  text-align: center;

  & + & {
    border-left: 1px solid #e7e7e7;
  }
`;

export const TableCheckbox = styled(Checkbox)`
  padding: 0;
  margin: -5px;
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

export const TableDateField = styled(DateField)`
  .MuiInputBase-root {
    width: 100%;
    padding: 0;
  }
  .MuiOutlinedInput-input {
    height: 30px;
    padding: 0;
    font-size: 12px;
    text-align: center;
  }
  .MuiOutlinedInput-notchedOutline {
    top: 0;
    border: none;
  }
`;
