import React, { ReactNode } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BasicTable } from './BasicTable';
import { TableData, TableHeadData, TableRow } from './StyledComponents';

export interface SimpleTableProps<T> {
  header?: ReactNode;
  columns: ColumnDef<T, any>[];
  data: T[];
  footer?: ReactNode;
}
/**
 * 단순히 데이터를 보여주는 용도의 Table
 */
export const SimpleTable = <T extends object>({
  header,
  columns,
  data,
  footer,
}: SimpleTableProps<T>) => {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <BasicTable
      thead={
        <>
          {header}
          <TableRow>
            {table.getFlatHeaders().map(header => (
              <TableHeadData key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </TableHeadData>
            ))}
          </TableRow>
        </>
      }
      tbody={
        table.getRowModel().rows.map(row => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map(cell => (
              <TableData
                key={cell.id}
                style={{ width: `${cell.column.getSize()}px` }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableData>
            ))}
          </TableRow>
        ))
      }
      tfoot={footer}
    />
  );
};
