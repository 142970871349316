import styled from 'styled-components';
import { Button } from '../../common/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  margin-top: 10px;
  gap: 20px;
`;

interface GatewayListButtonsProps {
  isUpdate: boolean;
  onClickUpdate: () => void;
  onClickCancel: () => void;
}
export const GatewayListButtons = ({
  isUpdate,
  onClickUpdate,
  onClickCancel,
}: GatewayListButtonsProps) => {
  return (
    <Wrapper>
      <Button onClick={onClickUpdate}>{isUpdate ? '수정': '추가'}</Button>
      <Button onClick={onClickCancel}>취소</Button>
    </Wrapper>
  );
};
