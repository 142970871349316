import styled from 'styled-components';
import { Tooltip as MuiTooltip } from '@mui/material';

export const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
`;

export const Spacer = styled.div<{ $width?: string; $height?: string }>`
  width: ${p => p.$width ?? '100%'};
  height: ${p => p.$height ?? '100%'};
`;

export const CheckboxLabel = styled.span`
  font-weight: bold;
`;

export const Tooltip = styled(MuiTooltip)`
  MuiTooltip-tooltip {
    max-width: 200px;
  }
`;