import styled from 'styled-components';
import { Button } from '../../common/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
`;

interface GatewayListButtonsProps {
  onClickAdd: () => void;
  onClickDelete: () => void;
}
export const GatewayListButtons = ({
  onClickAdd,
  onClickDelete,
}: GatewayListButtonsProps) => {
  return (
    <Wrapper>
      <Button onClick={onClickAdd}>추가</Button>
      <Button onClick={onClickDelete}>삭제</Button>
    </Wrapper>
  );
};
