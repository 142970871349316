import {
  createGateway,
  deleteGateway,
  GatewayInformation,
  requestGateway,
  requestGatewayJson,
  requestGateways,
  updateGateway,
} from '../api/gateway';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MutationOption, QueryOption } from './utils';
import { AxiosResponse } from 'axios';

export const useGateways = (options?: QueryOption<GatewayInformation>[]) => {
  const fetch = async () => {
    const { data } = await requestGateways();
    return data;
  };

  return useQuery({ queryKey: ['GATEWAYS'], queryFn: fetch, ...options });
};

interface GatewayParam {
  id: string;
  options?: QueryOption<GatewayInformation>;
}
export const useGateway = ({ id, options }: GatewayParam) => {
  const fetch = async () => {
    const { data } = await requestGateway(id);
    return data;
  };

  return useQuery({ queryKey: ['GATEWAY', id], queryFn: fetch, ...options });
};

export const useCreateGateway = (
  options?: MutationOption<GatewayInformation, AxiosResponse<void>>,
) => {
  const fetch = async (gateway: GatewayInformation) => createGateway(gateway);

  return useMutation({ mutationFn: fetch, ...options });
};

export const useUpdateGateway = (
  options?: MutationOption<GatewayInformation, AxiosResponse<void>>,
) => {
  const fetch = async (gateway: GatewayInformation) => updateGateway(gateway);

  return useMutation({ mutationFn: fetch, ...options });
};

export const useDeleteGateway = (
  options?: MutationOption<string, AxiosResponse<void>>,
) => {
  const fetch = async (id: string) => deleteGateway(id);

  return useMutation({ mutationFn: fetch, ...options });
};

export const useGatewayJson = (
  id: string,
  options?: QueryOption<string>,
) => {
  const fetch = async () => {
    const { data } = await requestGatewayJson(id);
    return data;
  };

  return useQuery({ queryKey: ['GATEWAY_JSON', id], queryFn: fetch, enabled: !!id.length, ...options });
};
