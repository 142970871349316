import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import React from 'react';
import { Tooltip } from '../../common/StyledComponents';

export const PacsTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버"
        >
          <TableRow>
            <TableHeadData>pacsFindHost</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsFindHost}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsFindHost: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버의 포트"
        >
          <TableRow>
            <TableHeadData>pacsFindPort</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsFindPort}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsFindPort: +e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버의 AE Title"
        >
          <TableRow>
            <TableHeadData>pacsFindAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsFindAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsFindAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Optional. default value: FINDSCU. Dicom 요청 시 사용하는 SCU AE Title"
        >
          <TableRow>
            <TableHeadData>pacsFindScuAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsFindScuAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsFindScuAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버"
        >
          <TableRow>
            <TableHeadData>pacsMoveHost</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsMoveHost}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsMoveHost: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버의 포트"
        >
          <TableRow>
            <TableHeadData>pacsMovePort</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsMovePort}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsMovePort: +e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버의 AE Title"
        >
          <TableRow>
            <TableHeadData>pacsMoveAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsMoveAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsMoveAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Optional. default value: MOVESCU. Dicom 요청 시 사용하는 SCU AE Title"
        >
          <TableRow>
            <TableHeadData>pacsMoveScuAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsMoveScuAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsMoveScuAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버"
        >
          <TableRow>
            <TableHeadData>pacsStoreHost</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsStoreHost}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsStoreHost: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버의 포트"
        >
          <TableRow>
            <TableHeadData>pacsStorePort</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsStorePort}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsStorePort: +e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom 요청 시 연결할 서버의 AE Title"
        >
          <TableRow>
            <TableHeadData>pacsStoreAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsStoreAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsStoreAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Optional. default value: STORESCU. Dicom 요청 시 사용하는 SCU AE Title"
        >
          <TableRow>
            <TableHeadData>pacsStoreScuAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.pacsStoreScuAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        pacsStoreScuAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: false. Dicom Find 사용 시 결과값을 ISO_IR 192로 받도록 설정할지 여부"
        >
          <TableRow>
            <TableHeadData>pacsSetCharset</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.pacsSetCharset}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, pacsSetCharset: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom Move 사용 시 remora가 dicom 요청을 수신하기 위한 포트"
        >
          <TableRow>
            <TableHeadData>hgatePort</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.hgatePort}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        hgatePort: +e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. Dicom Move 사용 시 remora가 dicom 요청을 수신하기 위한 AE Title"
        >
          <TableRow>
            <TableHeadData>hgateAeTitle</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.hgateAeTitle}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        hgateAeTitle: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
