import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableDateField, TableHeadData, TableRow } from '../../common/Table';
import dayjs from 'dayjs';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import React from 'react';
import { Tooltip } from '../../common/StyledComponents';

export const GatewayLoadFormTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. default value: false. PACS series 레벨 검색 기능"
        >
          <TableRow>
            <TableHeadData>gatewayLoadSeriesSearchEnabled</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.gatewayLoadSeriesSearchEnabled}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, gatewayLoadSeriesSearchEnabled: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: false. 발급 시 다이콤 태그를 이용한 사용자 인증 여부"
        >
          <TableRow>
            <TableHeadData>gatewayLoadAuthorizeEnabled</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.gatewayLoadAuthorizeEnabled}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, gatewayLoadAuthorizeEnabled: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: ACCEPTED. 신청 시 기본 상태"
        >
          <TableRow>
            <TableHeadData>gatewayLoadDefaultStatus</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayLoadDefaultStatus}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayLoadDefaultStatus: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: PT10M. 발급 영상의 만료 시간"
        >
          <TableRow>
            <TableHeadData>gatewayLoadExpireTime</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayLoadExpireTime}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayLoadExpireTime: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Optional. 설정 시 발급 승인된 영상들을 매일 설정된 시간에 전달"
        >
          <TableRow>
            <TableHeadData>gatewayLoadReadyTime</TableHeadData>
            <TableData>
              <TableDateField
                defaultValue={dayjs(gateway?.config?.gatewayLoadReadyTime ?? null)}
                onChange={v =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayLoadReadyTime: v?.isValid() ? v : undefined,
                      } } as GatewayInformation))
                }
                format="YYYY-MM-DD"
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
