import axios from 'axios';
import { BACKEND_URL, KEYCLOAK_LOGIN_URL } from '../env';

const  axiosInst = axios.create({
  baseURL: `${BACKEND_URL}/sardine`,
  headers: {
    post: {
      'Content-Type': 'application/json',
    },
  },
});

export const loginHandler = (error: any) => {
  if (error.response.status && error.response.status === 401) {
    location.href = new URL(`${BACKEND_URL}${KEYCLOAK_LOGIN_URL}`).href;
  }
  console.debug('err:', JSON.stringify(error.response.data ?? ''));

  return Promise.reject(error);
};

axiosInst.interceptors.response.use(response => response, loginHandler);

export default axiosInst;
