import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableData, TableHeadData, TableRow } from '../../common/Table';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import { Tooltip } from '../../common/StyledComponents';

export const AdapterFormTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="right"
          title="Required. default value: HGate. 관리자 페이지에 표시되는 이름"
        >
          <TableRow>
            <TableHeadData>gatewayName</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayName}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayName: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Required. default value: dicom. 저장할 영상과 같은 파일을 임시로 저장하는데 사용되는 경로. 디렉토리가 없으면 자동으로 생성해줌"
        >
          <TableRow>
            <TableHeadData>gatewayTempStoragePath</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayTempStoragePath}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayTempStoragePath: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Required. default value: EmrRepositoryImpl. EMR 구현체 설정"
        >
          <TableRow>
            <TableHeadData>gatewayRepositoryAdapter</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayRepositoryAdapter}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayRepositoryAdapter: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Required. default value: FindPacsServiceImpl. PACS Find 구현체 설정"
        >
          <TableRow>
            <TableHeadData>gatewayFindAdapter</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayFindAdapter}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayFindAdapter: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Required. default value: MovePacsServiceImpl. PACS Move 구현체 설정"
        >
          <TableRow>
            <TableHeadData>gatewayMoveAdapter</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayMoveAdapter}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayMoveAdapter: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Required. default value: StorePacsServiceImpl. PACS Store 구현체 설정"
        >
          <TableRow>
            <TableHeadData>gatewayStoreAdapter</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayStoreAdapter}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayStoreAdapter: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="right"
          title="Optional. StoreEventHandler 구현체 설정"
        >
          <TableRow>
            <TableHeadData>gatewayStoreHandlerAdapter</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayStoreHandlerAdapter}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayStoreHandlerAdapter: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
