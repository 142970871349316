import { GatewayConfigProps } from './GatewayConfig';
import { BasicTable, TableCheckbox, TableData, TableHeadData, TableRow } from '../../common/Table';
import React from 'react';
import { GatewayInformation } from '../../api/gateway';
import { SeamlessInput } from '../../common/SeamlessInput';
import { Tooltip } from '../../common/StyledComponents';

export const GatewayStoreFormTable = ({
  gateway,
  onChangeGateway,
}: GatewayConfigProps) => (
  <BasicTable
    tbody={
      <>
        <Tooltip
          placement="left"
          title="Required. default value: READY. 신청 시 기본 상태"
        >
          <TableRow>
            <TableHeadData>gatewayStoreDefaultStatus</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayStoreDefaultStatus}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayStoreDefaultStatus: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: PT24H. 저장 실패한 영상의 재시도를 위해 삭제하지 않고 기다리는 시간"
        >
          <TableRow>
            <TableHeadData>gatewayStoreBackupTime</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayStoreBackupTime}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayStoreBackupTime: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: false. 환자 정보가 없는 초진 환자의 영상 등록 허용 여부"
        >
          <TableRow>
            <TableHeadData>gatewayStoreAllowNoRewrite</TableHeadData>
            <TableData>
              <TableCheckbox
                checked={gateway?.config?.gatewayStoreAllowNoRewrite}
                onChange={e =>
                  onChangeGateway(gateway => ({
                    ...gateway,
                    config: { ...gateway?.config, gatewayStoreAllowNoRewrite: e.target.checked },
                  } as GatewayInformation))
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
        <Tooltip
          placement="left"
          title="Required. default value: store. 등록할 영상을 저장할 경로"
        >
          <TableRow>
            <TableHeadData>gatewayStoreStoragePath</TableHeadData>
            <TableData>
              <SeamlessInput
                value={gateway?.config?.gatewayStoreStoragePath}
                onChange={e =>
                  onChangeGateway(gateway =>
                    ({ ...gateway,
                      config: {
                        ...gateway?.config,
                        gatewayStoreStoragePath: e.target.value,
                      } } as GatewayInformation),
                  )
                }
              />
            </TableData>
          </TableRow>
        </Tooltip>
      </>
    }
  />
);
